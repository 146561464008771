import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { Product } from '../../../classes/product';
import { HomepageService } from '../../../services/homepage.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common-service.service';

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default
  @Input() type: string = 'fashion'; // Default Fashion

  public imgPath = environment.imgURL;
  public products: any;
  public currencyValue: any
  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public Service: HomepageService, public currency: CommonService) {
    this.newArrival()

    this.currency.getCurrency().subscribe((observer) => {
      this.currencyValue = observer.currency;
    });
    // this.productService.getProducts.subscribe(response => 
    //   this.products = response.filter(item => item.type == this.type)
    // );
  }

  ngOnInit(): void {

  }
  public productId(id:any){
    this.Service.productId.next(id);
    localStorage.setItem('Product_Id',id);
  }
  public newArrival() {
    this.Service.getNewArrival().subscribe(data => {
      console.log(data);
      this.products = data
    })
  }
}
