import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { HomepageService } from '../../services/homepage.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common-service.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  productdata: any;
  img1: any;
  myUserId: any
  currencyValue: any;
  imgPath = environment.imgURL;
  public products: Product[] = [];
  public search: boolean = false;

  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]
  public currencyImage = 'assets/images/icon/inr.png';
  public altImage = 'assets/images/icon/dollar.png';
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    public homeService: HomepageService,
    private commonService: CommonService) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
    this.myUserId = localStorage.getItem('userid');
    this.getwishlist(this.myUserId)
    this.commonService.getCurrency().subscribe((observer) => {
      this.currencyValue = observer.currency;
    });
  }

  searchToggle() {
    this.search = !this.search;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }
  getwishlist(myUserId: any) {
    this.homeService.GetCartByUserId(myUserId).subscribe(data => {
      this.productdata = data
    })
  }

  getCurrencyChange(currencyValue: string) {
    this.currencyImage = 'assets/images/icon/' + currencyValue + '.png'
    this.commonService.setCurrency({
      currency: currencyValue
    });
  }

}
