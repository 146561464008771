import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	img?:any;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/shop/collection/left/sidebar',title: 'Men', type: 'link', active: false, children: [
				{ path: '/shop/collection/left/sidebar', title: 'ring', type: 'link' ,img:"../../../../assets/images/menu/ring.jpg" },
				{ path: '/shop/collection/left/sidebar', title: 'chain', type: 'link' ,img:"../../../../assets/images/menu/chain.jpg"},
				{ path: '/shop/collection/left/sidebar', title: 'bracelet', type: 'link' ,img:"../../../../assets/images/menu/bracelet.jpg"},
				{ path: '/shop/collection/left/sidebar', title: 'gemstone', type: 'link' ,img:"../../../../assets/images/menu/jewelset.jpg"}
			
			]
		},
		{
			title: 'women', type: 'sub', active: false, children: [
				{ path: '/shop/collection/left/sidebar', title: 'chain', type: 'link', img:"../../../../assets/images/menu/chain.jpg" },
				{ path: '/shop/collection/left/sidebar', title: 'earrings', type: 'link',img:"../../../../assets/images/menu/jumka.jpg" },
				{ path: '/shop/collection/left/sidebar', title: 'bracelet', type: 'link',img:"../../../../assets/images/menu/bracelet.jpg" },
				{ path: '/shop/collection/left/sidebar', title: 'jewel set', type: 'link' ,img:"../../../../assets/images/menu/jewelset.jpg" },
				{ path: '/shop/collection/left/sidebar', title: 'bangles', type: 'link',img:"../../../../assets/images/menu/bangle2.jpg" },
				{ path: '/shop/collection/left/sidebar', title: 'harams', type: 'link',img:"../../../../assets/images/menu/haram.jpg" },
				{ path: '/shop/collection/left/sidebar', title: 'necklace', type: 'link' ,img:"../../../../assets/images/menu/necklace.jpg"},
				 { path: '/shop/collection/left/sidebar', title: 'ring', type: 'link' ,img:"../../../../assets/images/menu/ring.jpg"},
				 { path: '/shop/collection/left/sidebar', title: 'pendant', type: 'link' ,img:"../../../../assets/images/menu/pendant.jpg"}
				
				
			]
		},
		{
			title: 'kids', type: 'sub', active: false, children: [
			
						{ path: '/shop/collection/left/sidebar', title: 'chain', type: 'link' ,img:"../../../../assets/images/menu/chain.jpg"},
						{ path: '/shop/collection/left/sidebar', title: 'ring', type: 'link' ,img:"../../../../assets/images/menu/ring2.jpg"},
						{ path: '/shop/collection/left/sidebar', title: 'bracelet', type: 'link' ,img:"../../../../assets/images/menu/bracelet2.jpg"},
						{ path: '/shop/collection/left/sidebar', title: 'bangles', type: 'link' ,img:"../../../../assets/images/menu/bangle2.jpg"}
				
			
				
			]
		},
		
		{
			title: 'jewel set', type: 'sub', badge: true, badgeText: 'new', active: false, children: [
			
				
						{ path: '/shop/collection/left/sidebar', title: 'bridal-set', type: 'link',img:"../../../../assets/images/menu/anklets.jpg" },
						{ path: '/shop/collection/left/sidebar', title: 'wedding-rings', type: 'link' ,img:"../../../../assets/images/menu/ring2.jpg"},
						{ path: '/shop/collection/left/sidebar', title: 'mangalsutra', type: 'link' ,img:"../../../../assets/images/menu/jewelset.jpg"},
					]
				
			
			
		},
		{
			path: '/pages/wishlist',title: 'customize', type: 'link', active: false
		
		},
		{
			path: '/pages/wishlist',title: 'blogs', type: 'link', active: false
		}
	];

	LEFTMENUITEMS: Menu[] = [
	
		{
			title: 'Men', type: 'sub', active: false, children: [
				{ path: '/men/vegetable', title: 'ring', type: 'link' },
				{ path: '/men/watch', title: 'chain', type: 'link' },
				{ path: '/men/goldncart', title: 'bracelet', type: 'link' },
				{ path: '/men/goldncart', title: 'gemstone', type: 'link' }
			
			]
			 
		},
		{
			title: 'women', type: 'sub', active: false, children: [
				{ path: '/shop/collection/left/sidebar', title: 'chain', type: 'link' },
				{ path: '/shop/collection/right/sidebar', title: 'earrings', type: 'link' },
				{ path: '/shop/collection/no/sidebar', title: 'bracelet', type: 'link' },
				{ path: '/shop/collection/infinitescroll', title: 'jewel set', type: 'link' },
				{ path: '/shop/collection/infinitescroll', title: 'bangles', type: 'link' },
				{ path: '/shop/collection/infinitescroll', title: 'harams', type: 'link' },
				{ path: '/shop/collection/infinitescroll', title: 'necklace', type: 'link' },
				 { path: '/shop/collection/infinitescroll', title: 'ring', type: 'link' },
				 { path: '/shop/collection/infinitescroll', title: 'pendant', type: 'link' }
			]
		},
		{
			title: 'kids', type: 'sub', active: false, children: [
			
						{ path: '/shop/product/left/sidebar/trim-dress', title: 'chain', type: 'link' },
						{ path: '/shop/product/right/sidebar/trim-dress', title: 'ring', type: 'link' },
						{ path: '/shop/product/no/sidebar/trim-dress', title: 'bracelet', type: 'link' },
						{ path: '/shop/product/no/sidebar/trim-dress', title: 'bangles', type: 'link' }
				
			
				
			]
		},	{
			title: 'jewel set', type: 'sub', active: false, children: [
			
				
				{ path: '/pages/portfolio/grid/two', title: 'bridal-set', type: 'link' },
				{ path: '/pages/portfolio/grid/three', title: 'wedding-rings', type: 'link' },
				{ path: '/pages/portfolio/grid/four', title: 'mangalsutra', type: 'link' },
			]
		},
		{
			title: 'cutomize', type: 'link', active: false, path: '/home/fashion'
		},
		// {
		// 	path: '/home/fashion', title: 'house of design', type: 'link'
		// },
		{
			path: '/home/fashion',title: 'blogs', type: 'link', active: false,
		},
		// {
		// 	path: '/home/fashion', title: 'home & decor', type: 'link'
		// },
		// {
		// 	path: '/home/fashion', title: 'kitchen', type: 'link'
		// }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
