<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-right" (click)="mainMenuToggle()">
          <span>Back</span>
          <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->
        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type === 'sub'" 
            (click)="toggletNavActive(menuItem)" (click)="menusearch(menuItem.menuId)">
           
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children" ></span>
        </a>
        <!-- Link -->
        <a (click)="menusearch(menuItem.menuId)">
         <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: menuItem.title}" 
         *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
        </a>
          <span class="sub-arrow" *ngIf="menuItem.children" (click)="toggletNavActive(menuItem)"></span>
        </a>

        <!-- 2nd Level Menu -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children && menuItem.children.length<=4" >
          <li style="padding-left: 12px; padding-right: 5px;" *ngFor="let childrenItem of menuItem.children">
           
            <!-- Link -->
            <a style="padding: 3px;" [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: childrenItem.title}" 
              *ngIf="childrenItem.type === 'link' " (click)="submenusearch(childrenItem.subMenuId)">
              <div class="row">
              <div class="col-md-4 col-sm-4">  <img src="{{childrenItem.img}}" style="width: 40px; height: 40px;"/></div>
            <div style="padding-top: 12px;" class="col-md-8 col-sm-8"><p>{{ childrenItem.title | translate }}</p><span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span></div>
            </div>
              
            </a>
         
          </li>
        </ul>
        
        <ul class="nav-submenu customstylemenu" [class.opensubmenu]="menuItem.active"  *ngIf="menuItem.children && menuItem.children.length>4" >
          
          <div class="col-md-12">
          <li style="padding-left: 12px; padding-right: 5px;" *ngFor="let childrenItem of menuItem.children">
           
            <!-- Link -->
            <a style="padding: 3px;" [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: childrenItem.title}"  
              *ngIf="childrenItem.type === 'link' " (click)="submenusearch(childrenItem.subMenuId)">
              <!-- [routerLink]="['/shop/collection/left/sidebar']"
                       [queryParams]="{ category: category.title}" -->
              <div class="row">
                <div class="col-md-4"><img src= '{{childrenItem.img}}'  style="width: 40px; height: 40px;"/></div>
                <div style="padding-top: 12px;" class="col-md-8"><p>{{ childrenItem.title | translate }}</p>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span></div>
              </div>
            </a>
          </li>
        </div>
        </ul>


       
      </li>
    </ul>
  </div>
</div>
