import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { HomepageService } from '../../services/homepage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems;
  data: any;

  constructor(private router: Router, public navServices: NavService,private homeservice:HomepageService) {
  
  }

  ngOnInit(): void {
    this.allMenulist()
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
    
  }
  allMenulist(){

    this.homeservice.GetAllMenuList().subscribe(list => {
      console.log(list);
      this.menuItems=list
    })

  }
  menusearch(id){
    this.data={'id':id ,'value':"menu"};
    localStorage.setItem('Filter_Id',JSON. stringify(this.data));
    this.homeservice.menuchoose.next(this.data);
  }
  submenusearch(id){
    this.data={'id':id ,'value':"submenu"};
    localStorage.setItem('Filter_Id',JSON. stringify(this.data));
    this.homeservice.menuchoose.next(this.data);
  }
}
