import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { HomepageService } from 'src/app/shared/services/homepage.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common-service.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {
  // public productCollections: any[] = [];
  @Input() product: Product;
  @Input() productCollections: any;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string
  img1: string;
  data: any;
  userName: any;
  myUserId: any;
  imgPath = environment.imgURL;
  quickviewdata: any;
  currencyValue: any;
  constructor(private productService: ProductService,
    private homeService: HomepageService,
    private commonService: CommonService, public router: Router) { }

  ngOnInit(): void {

    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.img1 = this.imgPath + this.product.img
    this.userName = localStorage.getItem('username');
    this.commonService.getCurrency().subscribe((observer) => {
      this.currencyValue = observer.currency;
    });
  }
  /**
   * @param id product Id
   * 
  */
public productId(id:any){
  this.homeService.productId.next(id);
  localStorage.setItem('Product_Id',id);
}
  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      console.log(item);

      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(id: any) {
    this.myUserId = localStorage.getItem('userid');
    let cartdata = {
      productId: id,
      userId: this.myUserId
    }
    this.homeService.SaveCart(cartdata).subscribe(cartdata => {
      this.data = cartdata
      console.log(this.data);

    })
  }

  addToWishlist(id: any) {
    this.myUserId = localStorage.getItem('userid');
    let wishdata = {
      productId: id,
      userId: this.myUserId
    }
    this.homeService.SaveWishlist(wishdata).subscribe(wishdata => {
      this.data = wishdata
      console.log(this.data);

    })
  }

  addToCompare(id: any) {
    // this.productService.addToCompare(product);
    localStorage.setItem('compare',id);
    this.myUserId = localStorage.getItem('userid');
    if(this.myUserId){
      alert("gg")
      
      let comparedata = {
        productId: id,
        userId: this.myUserId
      }
      this.homeService.SaveCompare(comparedata).subscribe(comparedata => {
        this.data = comparedata
        console.log(this.data);
      })
    }
  else{
    this.router.navigateByUrl[('/shop/login')]
    Swal.fire({
      title: 'Warning!',
      icon: 'warning',
      text: 'You nn ',
      confirmButtonText: 'Close'
    });
  }

  }
  }
  //   GetProductDetails(){
  //     this.homeService.GetAllOnsaleProductDetails().subscribe(data => {
  //       console.log(data);
  //       this.product = data;
  //     console.log(this.product);

  //   })
  // }

