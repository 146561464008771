import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { HomepageService } from '../../services/homepage.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;

  constructor(private homeService:HomepageService, public router:Router) { }
header:any;
  ngOnInit(): void {
    this.GetAllSliderList()
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }
  GetAllSliderList(){

    this.homeService.GetHeaderDetails().subscribe(data => {
      console.log(data);
      this.header=data
    })
  }
//   login(){
//     this.router.navigateByUrl['/pages/login']
// }
showCompare(){
  if(localStorage.getItem('userid') != null){

  }
}
logout(){
  Swal.fire({
    title: 'Are you sure?',
  
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, LogOut it!'
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage.removeItem('userid');
      window.location.reload();
          Swal.fire(
        'Deleted!',
        'LogOut Successfully.',
        'success'
      )
  
    
    }
  })
  
}
}
