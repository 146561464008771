import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { HomepageService } from '../../services/homepage.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categorydata:any;
  menudata: any;
  public products: Product[] = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService, public homeService: HomepageService) { 
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.filterbyCategory() 
  }

  filterbyCategory() {
    this.homeService.getmenufilter().subscribe(data =>{
      this.categorydata= data
      console.log(this.categorydata);
      
    })
    // const category = [...new Set(this.products.map(product => product.type))]
    // return category
  }
  getbymenuId(id:any){
    this.menudata={'id':id ,'value':"menu"};
    this.homeService.menuchoose.next(this.menudata);
  //   this.homeService.GetProductFilterbyMenuid(id).subscribe(data => {
  //     console.log(data);
  //     this.menudata=data 
      
  // })
}
menusearch(id){
 

}
}
