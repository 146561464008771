import { Component, OnInit, Input } from '@angular/core';
import { HomepageService } from '../../services/homepage.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo-12.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  footer:any;
  constructor(private homeService:HomepageService) { }

  ngOnInit(): void {
    this.GetFooterList();
  }
  GetFooterList(){

    this.homeService.GetFooterDetails().subscribe(data => {
      console.log(data);
      this.footer=data
    })
  }
}
