<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Gold'n'cart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<!-- <h4>{{footer[0].title}}</h4> -->
						<h4>About</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<!-- <p>{{footer[0].children[0].description}}</p> -->
						<p>“Wearing Jewellery on the Day Name Ending with Y Is the Sign, That You Have Good Jewelry Collection.” “Jewelry Is an Outlet for Expression.” “Wearing Beautiful Jewellery Makes Every Moment Great.” “Jewellery Is Like Ice Cream, There's Always Room for More.”</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <!-- <h4>{{footer[2].title}}</h4> -->
							<h4>CATEGORIES</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <!-- <li><a href="javascript:void(0)">{{footer[1].children[0].description}}</a></li>
                                <li><a href="javascript:void(0)">{{footer[1].children[1].description}}</a></li>
                                <li><a href="javascript:void(0)">{{footer[1].children[2].description}}</a></li>
                                <li><a href="javascript:void(0)">{{footer[1].children[3].description}}</a></li> -->
								<li><a href="javascript:void(0)">Womens Jewels</a></li>
                                <li><a href="javascript:void(0)">Mens Jewels</a></li>
                                <li><a href="javascript:void(0)">Kids Jewels</a></li>
                                <li><a href="javascript:void(0)">Customized</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <!-- <h4>{{footer[1].title}}</h4> -->
							<h4>WHY WE CHOOSE</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <!-- <li><a href="javascript:void(0)">{{footer[2].children[0].description}}</a></li>
                                <li><a href="javascript:void(0)">{{footer[2].children[1].description}}</a></li>
                                <li><a href="javascript:void(0)">{{footer[2].children[2].description}}</a></li>
                                <li><a href="javascript:void(0)">{{footer[2].children[3].description}}</a></li>
                                <li><a href="javascript:void(0)">{{footer[2].children[4].description}}</a></li> -->

								<li><a href="javascript:void(0)">Shipping & Return</a></li>
                                <li><a href="javascript:void(0)">Secure Shopping</a></li>
                                <li><a href="javascript:void(0)">Gallery</a></li>
                                <li><a href="javascript:void(0)">Affiliates</a></li>
                                <li><a href="javascript:void(0)">Contacts</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <!-- <h4>{{footer[3].title}}</h4> -->
							<h4>STORE INFORMATION</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <!-- <li><i class="fa {{footer[3].children[0].icon}}"></i>{{footer[3].children[0].description}}</li>
						        <li><i class="fa {{footer[3].children[1].icon}}"></i>{{footer[3].children[1].description}}</li>
						        <li><i class="fa {{footer[3].children[2].icon}}"></i>{{footer[3].children[2].description}}</li> -->
						        <!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
								<li><i class="fa fa-map-marker"></i>Goldencart Store, Kaniyakumari India 629201</li>
						        <li><i class="fa fa-phone"></i>Call Us: +91 9498029898</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: Contact@Goldncart.Com</li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} theme forest powered by ckssolution</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->