import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    public isLoading = new BehaviorSubject(false);
    currencySubject$ = new BehaviorSubject<any>({
        currency: 'inr'
    });

    getCurrency() {
        return this.currencySubject$;
    }
    setCurrency(currencyValue: any) {
        this.currencySubject$.next(currencyValue);
    }
}