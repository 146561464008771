import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {
  url = environment.API_URL;
  constructor(private http: HttpClient) { }
  public menuchoose = new BehaviorSubject<any>('');
  public productId = new BehaviorSubject<any>('');
  login(data: string) {
    // let postData = {"userName" : userName ,"password" :password};

    return this.http.post(this.url + `api/Auth/login`, data);
  }

  requestingotp(data: string) {
    return this.http.get(this.url + `api/Sms?ToNumber=%2B918754104436`);

  }
  otplogin(data: any) {
    return this.http.post(this.url + `api/OtpValidation/OtpLogin`, data);
  }
  GetAllSliderList() {

    return this.http.get(this.url + "api/Slider/GetAllSliderList");

  }
  GetAllMenuList() {

    return this.http.get(this.url + "api/Menu/GetMenuList");

  }
  GetAllbannerList() {

    return this.http.get(this.url + "api/Banner/GetAllBannerList");

  }
  GetALLBlogList() {

    return this.http.get(this.url + "api/Blog/GetAllBlogList");

  }
  GetALLParallaxList() {

    return this.http.get(this.url + "api/ParallaxBannerDetails/GetAllParallaxBannerList");

  }
  GetHeaderDetails() {

    return this.http.get(this.url + "api/HeaderDetails/GetAllHeaderDetails");

  }
  GetFooterDetails() {

    return this.http.get(this.url + "api/Footer/GetFooterDetails");

  }
  GetAllOnsaleProductDetails() {
    return this.http.get(this.url + "api/OnSale/GetOnSale");
  }
  GetWishlistByUserId(id: any) {
    return this.http.get(this.url + `api/WishList/GetWishListByUserId?userId=${id}`);
  }
  SaveWishlist(data: any) {
    return this.http.post(this.url + `api/WishList/SaveWishlist`, data);
  }
  DeleteWishlistById(id: any) {
    return this.http.delete(this.url + `api/WishList/DeleteWishList?UserId=${id.UserId}&ProductId=${id.ProductId}`);
  }
  SaveCompare(data: any) {
    return this.http.post(this.url + `api/Compare/SaveCompare`, data);
  }
  GetCompareByUserId(id: any) {
    return this.http.get(this.url + `api/Compare/GetCompareByUserId?UserId=${id}`);
  }
  DeleteComments(id: any) {
    return this.http.delete(this.url + `api/Comments/DeleteCommentsDetails?CommentsDetailsId=${id}`);
  }
  DeleteCompareById(id: any) {
    return this.http.delete(this.url + `api/Compare/DeleteCompare?UserId=${id.UserId}&ProductId=${id.ProductId}`);
  }
  SaveCart(data: any) {
    return this.http.post(this.url + `api/AddToCart/SaveAddToCartList`, data);
  }
  GetCartByUserId(id: any) {
    return this.http.get(this.url + `api/AddToCart/GetAddToCartByUserId?UserId=${id}`);
  }
  DeleteCartById(id: any) {
    return this.http.delete(this.url + `api/AddToCart/DeleteAddToCart?UserId=${id.UserId}&ProductId=${id.ProductId}`);
  }
  SaveBlogComments(data: any) {
    return this.http.post(this.url + `api/Comments/SaveCommentsDetails`, data);
  }
  GetBlogById(id: any) {
    return this.http.get(this.url + `api/Blog/GetBlogDetailsByBlogId?BlogId=${id}`);
  }
  GetAllComments() {
    return this.http.get(this.url + "api/Comments/GetAllCommentsList");
  }
  public getSpecialProducts() {
    return this.http.get(this.url+"api/SpecialProducts/GetSpecialProductDetails");
  }
  getmenufilter(){
    return this.http.get(this.url+"api/Menu/GetAllMenuList");
  }
  //product filter API
  public GetProductFilterbyMenuid(id: any) { return this.http.get(this.url + `api/ProductDetails/GetAllProductFilters?MenuId=${id}`); }
  public GetProductFilterbyProductId(id: any) { return this.http.get(this.url + `api/ProductDetails/GetAllProductFilters?ProductId=${id}`); }
  public GetProductFilterbySubMenuId(id: any) { return this.http.get(this.url + `api/ProductDetails/GetAllProductFilters?SubMenuId=${id}`); }
  //Product Keyword Search
  public GetProductKeywordSearch(id: any) { return this.http.get(this.url + `api/ProductDetails/GetAllProductKeywordSearch?ProductName=${id}`); }
  //Get New Arrival
  public getNewArrival(){return this.http.get(this.url+"api/NewArrival/GetNewArrival");}
}
